import UploadFile from "../UploadFiles";
import Error from "../Error";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {Upload} from "../../service/upload";
import {SubmitHandler, useForm} from "react-hook-form";
import {BlobWithDescription} from "../ArticleForm";
import {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";

interface Props {
  onSubmit: (u: BlobWithDescription) => Promise<void>
  label: string
  upload?: Upload
  formats?: string
  withDescription?: boolean
  withFile?: boolean
}

const UploadForm: FunctionComponent<Props> = (
  {
    upload,
    onSubmit,
    formats = "video/*",
    label,
    withDescription = true,
    withFile = true
  }) => {
  const {t} = useTranslation();
  const {handleSubmit, register, formState: {errors}} = useForm<BlobWithDescription>();
  const [isLoading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [err, setErr] = useState<string | null>(null);

  const submitHandler: SubmitHandler<BlobWithDescription> = ({description, youtube_suffix}) => {
    if (file || !withFile || youtube_suffix) {
      setLoading(true)
      onSubmit({description: withDescription ? description : "...", youtube_suffix, file: withFile && file ? file : new File([], "placeholder")}).then(() => setLoading(false))
    } else {
      setErr(t("validationError.noFile"))
    }
  }
  const showUploadInput = withFile && !formats?.startsWith("video")
  const showUploadTextInput = withFile && formats?.startsWith("video")
  return (
    <Container>
      <Row>
        <Col>
          { showUploadTextInput && <Form.Group className="mb-3" controlId="youtube_suffix">
              <Form.Control
                  type="text"
                  defaultValue={upload ? upload.file : ""}
                  placeholder={t("form.media.YoutubeSuffix")}
                  {...register("youtube_suffix", {
                    value: upload ? upload.file : "",
                    required: {
                      value: true,
                      message: t("form.validationMessages.required")
                    },
                    minLength: {
                      value: 3,
                      message: t("form.validationMessages.min", {field: t("form.media.YoutubeSuffix")})
                    }
                  })}/>
              <Form.Text className="text-danger">
                {errors.description && errors.description.message}
              </Form.Text>
          </Form.Group>}
          { showUploadInput && <UploadFile onChange={(f) => setFile(f[0])} multiple={false} label={label} zone={true} formats={formats}/> }
          <form onSubmit={handleSubmit(submitHandler)}>
            <Error error={err}/>
            {withDescription && <Form.Group className="mb-3" controlId="description">
                <Form.Control
                    type="text"
                    defaultValue={upload ? upload.description : ""}
                    placeholder={t("form.media.Description")}
                    {...register("description", {
                      value: upload ? upload.description : "",
                      required: {
                        value: true,
                        message: t("form.validationMessages.required")
                      },
                      minLength: {
                        value: 3,
                        message: t("form.validationMessages.min", {field: t("form.media.Description")})
                      }
                    })}/>
                <Form.Text className="text-danger">
                  {errors.description && errors.description.message}
                </Form.Text>
            </Form.Group>
            }
            <Form.Group className="text-end">
              <Button type="submit" disabled={isLoading}>{upload ? t('link.update') : t('link.create')}</Button>
            </Form.Group>
          </form>
        </Col>
      </Row>
    </Container>
  )
}

export default UploadForm
