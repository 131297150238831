import Page from "../components/Page";
import {ArticleInput} from "../components/ArticleForm";
import {useHistory, useParams} from "react-router-dom";
import PressForm from "../components/PressForm";
import article, {Article} from "../service/article";
import React, {useCallback} from "react";
import useAsync, {isLoading} from "../hooks/async";
import Loader from "../components/Loader";

interface Params {
  slug: string
}

const CreatePhotoUpload = () => {
  const history = useHistory();

  const params = useParams<Params>();

  const promise = useCallback(() => {
    return article.getArticle(params.slug, "", "press");
  }, [params.slug]);

  const asyncState = useAsync<Article>(promise);

  const onSubmit = (a: ArticleInput) => {
    if (asyncState.data) {
      return article.edit(a, "", asyncState.data.id, [], "press").then(() => {
        history.push("/media")
      })
    }
    return Promise.resolve();
  }

  return (
    <Page titleImage="/img/page-titles/edit/edit-media" titleAlt={"Edit Media"}>
      <div>
        <Loader isLoading={isLoading(asyncState)}/>
        {asyncState.data && <PressForm
            article={asyncState.data}
            handleDelete={(id: number) => article.delete(id, "","press").then(() => history.push("/media"))}
            onSubmit={onSubmit}
        />
        }
      </div>
    </Page>
  )
}

export default CreatePhotoUpload;
