import {Reducer, useEffect, useReducer} from "react";

interface AsyncState<Type> {
  status: 'pending' | 'resolved' | 'rejected' | 'idle'
  data: Type | null
  error?: string | null
}

interface AsyncAction<Type> {
  type?: 'pending' | 'resolved' | 'rejected' | 'idle' | null
  data: Type | null
  error?: string | null
}

function asyncReducer<Type>(state: AsyncState<Type>, action: AsyncAction<Type>) {
  switch (action.type) {
    case 'pending': {
      return {status: action.type, data: null, error: null}
    }
    case 'resolved': {
      return {status: action.type, data: action.data, error: null}
    }
    case 'rejected': {
      return {status: action.type, data: null, error: action.error}
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function useAsync<Type> (asyncCallback: () => Promise<Type>): AsyncState<Type> {
  const [state, dispatch] = useReducer<Reducer<AsyncState<Type>, AsyncAction<Type>>>(asyncReducer, {
    status: 'idle',
    data: null,
    error: null,
  });

  useEffect(() => {
    const promise = asyncCallback();
    if (!promise) {
      return
    }
    dispatch({type: 'pending', data: null})
    promise.then(
      data => {
        dispatch({type: 'resolved', data})
      },
      error => {
        dispatch({type: 'rejected', error, data: null})
      },
    )
  }, [asyncCallback]);

  return state;
}

export function isLoading<Type> (state: AsyncState<Type>) {
  return state.status !== 'resolved' && state.status !== 'rejected';
}

export default useAsync;
