import {Link} from 'react-router-dom';
import DateBox from "../DateBox";
import {Article} from "../../service/article";

interface Props {
  article: Article
}

const NewsBox: React.FunctionComponent<Props> = ({article}) => {

  return (
    <Link className="news-box d-block" to={`/news/${article.slug}`}>
      <DateBox date={article.created_at}/>
      <div className="news-short pt-4">
        {article.title}
      </div>
    </Link>
  )
}


export default NewsBox
