import Page from "../components/Page";
import Error from "../components/Error";
import {Button, Container, Form} from "react-bootstrap";
import {SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import ArticleService, {ArticleCategory} from "../service/article";
import {useHistory} from "react-router-dom";

interface ArticleCategy {
  name: string
}

const CreateArticleCategory = () => {
  const {handleSubmit, register, formState: {errors}} = useForm<ArticleCategy>();
  const [isLoading, setLoading] = useState(false);
  const {t, i18n} = useTranslation();
  const [err, setErr] = useState(null);
  const history = useHistory();

  const onSubmit: SubmitHandler<ArticleCategory> = ({name}) => {
    setLoading(true)
    ArticleService.createCategory(name, i18n.language).then(() => {
      setLoading(false)
      history.push("/compositions")
    }).catch((e) => {
      setLoading(false);
      setErr(e.message)
    });
  }

  return (
    <Page titleImage="/img/page-titles/create/create-compositions" titleAlt={"Create Article Categories"}>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Error error={err}/>
          <Form.Group className="mb-3" controlId="name">
            <Form.Control
              type="text"
              placeholder={t("form.article.Name")}
              {...register("name", {
                required: {
                  value: true,
                  message: t("form.validationMessages.required")
                },
                minLength: {
                  value: 3,
                  message: t("form.validationMessages.min", {field: t("form.article.Title")})
                }
              })}/>
            <Form.Text className="text-danger">
              {errors.name && errors.name.message}
            </Form.Text>
          </Form.Group>
          <Form.Group className="text-end">
            <Button type="submit" disabled={isLoading}>{t('link.create')}</Button>
          </Form.Group>
        </form>
      </Container>
    </Page>
  )
}

export default CreateArticleCategory;
