import upload, {Upload} from "../../service/upload";
import {FunctionComponent, useEffect, useState} from "react";
import {Col, Modal} from "react-bootstrap";
import {useAppContext} from "../../contexts/App/appContext";
import {useTranslation} from "react-i18next";

interface Props {
  images: Upload[]
  uploadCategory?: string
  uploadType?: string
  showDelete?: boolean
}

const ImageGallery: FunctionComponent<Props> = ({
                                                  images,
                                                  uploadCategory = "photo",
                                                  uploadType = "media",
                                                  showDelete = true
                                                }) => {
  const {state} = useAppContext();
  const {t} = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const handleNavigation = (e: KeyboardEvent) => {
    if (e.key === "ArrowLeft" && selectedIndex !== null && selectedIndex !== 0) {
      setSelectedIndex(selectedIndex - 1)
    } else if (e.key === "ArrowRight" && selectedIndex !== null && images.length > selectedIndex + 1) {
      setSelectedIndex(selectedIndex + 1)
    } else if (e.key === "Escape") {
      setSelectedIndex(null)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleNavigation);
    return () => document.removeEventListener('keydown', handleNavigation)
  })
  const image = selectedIndex !== null && images[selectedIndex] ? images[selectedIndex] : null;

  return (
    <div className="image-gallery row">
      {images.map((image, index) => {
        let padding = "px-1"
        if (index % 3 === 0) {
          padding = "pe-2";
        } else if (index % 3 === 2) {
          padding = "ps-2"
        }
        return (
          <Col lg={4} xs={12} key={`image-${image.id}`} className="position-relative pb-4">
            <div className="photo-delete" onClick={() => setSelectedIndex(index)}>
              {state.user && state.user.can_edit && showDelete && (
                <div className="photo-delete-icon">
                  <a href="/"
                     onClick={(e) => {
                       e.preventDefault()
                       e.stopPropagation()
                       upload.delete(uploadType, uploadCategory, image.id).then(() => window.location.reload())
                     }}>{t('link.delete')}</a>
                </div>)}
              <img src={image.file} alt={`presentation ${index}`} className={"img-fluid " + padding} height="100%"
                   onClick={() => setSelectedIndex(index)}/>

            </div>
            <div className="text-center"> {image.description} </div>
          </Col>
        )
      })
      }

      {
        image && <Modal className="image-preview" show={true} size="xl" onExit={() => setSelectedIndex(null)}
                        onHide={() => setSelectedIndex(null)}>
            <Modal.Body className="text-center">
                <div>
                    <img src={image.file} alt="preview" className="img-fluid"/>
                    <div className="text-center py-4"> {image.description} </div>
                </div>
            </Modal.Body>
        </Modal>
      }
    </div>
  )
}

export default ImageGallery
