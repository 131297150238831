import Page from "../components/Page";
import NewsForm from "../components/ArticleForm";
import ArticleService from "../service/article";
import {useTranslation} from "react-i18next";


const CreateArticle = () => {
  const {i18n} = useTranslation();

  return (
    <Page titleImage="/img/page-titles/create/create-news" titleAlt={"Create Article"}>
      <NewsForm
        handleChange={(input) => {
          return ArticleService.create(input, i18n.language, "news")
        }}
        listURL="/news"
        showImageUpload={true}
        showUploads={true}
      />
    </Page>
  )
}

export default CreateArticle;
