import {useTranslation} from "react-i18next";
import {Col, Row, Form, Button} from "react-bootstrap";
import {SubmitHandler, useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import Error from "../components/Error"
import UserService from "../service/user";
import setup from "../api";
import {SET_TOKEN, SET_USER_INFO, useAppContext} from "../contexts/App/appContext";
import {useHistory} from "react-router-dom";
import Page from "../components/Page";

interface LoginFormInput {
  email: string
  password: string
}

const Login = () => {
  const {t} = useTranslation();
  const {handleSubmit, register, formState: {errors}} = useForm<LoginFormInput>();
  const [err, setErr] = useState(null);
  const {state, dispatch} = useAppContext();
  const history = useHistory();

  useEffect(() => {
    if (state.token !== null) {
      history.push("/");
    }
  }, [history, state.token])

  const onLogin: SubmitHandler<LoginFormInput> = ({email, password}) => {
    UserService.login(email, password).then((token) => {
      setup(token.token);
      UserService.userInfo().then((info) => {
        dispatch({type: SET_USER_INFO, value: info});
        dispatch({type: SET_TOKEN, value: token.token});

        history.push('/');
      });
    }, (err) => {

      if (err.status === 403) {
        setErr(t("errors.login"))
      } else {
        setErr(t("errors.unknown"))
      }
    })
  };

  return (
    <Page titleImage="Login" titleAlt={t("nav.Login")}>
      <Row className="justify-content-center">
        <Col xs={12} md={4} lg={4} xl={4} xxl={3}>
          <form onSubmit={handleSubmit(onLogin)}>
            <Error error={err}/>
            <Form.Group className="mb-3" controlId="email">
              <Form.Control
                type="email"
                placeholder={t("form.login.Email")}
                {...register("email", {
                  required: {
                    value: true,
                    message: t("form.validationMessages.required")
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: t("form.validationMessages.invalid_format", {field: t("form.login.Email")})
                  }
                })}/>
              <Form.Text className="text-danger">
                {errors.email && errors.email.message}
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="email">
              <Form.Control
                className="form-control"
                type="password"
                placeholder={t("form.login.Password")}
                {...register("password", {
                  required: {
                    value: true,
                    message: t("form.validationMessages.required")
                  },
                  min: {
                    value: 6,
                    message: t("form.validationMessages.min", {field: t("form.login.Password")})
                  }
                })}/>
              <Form.Text className="text-danger">
                {errors.password && errors.password.message}
              </Form.Text>
            </Form.Group>
            <Button variant="primary" type="submit" className="float-end">
              {t("link.login")}
            </Button>
          </form>
        </Col>
      </Row>
    </Page>
  )
}

export default Login
