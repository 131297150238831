import React, {useCallback} from "react";
import {Col, Container, Row} from "react-bootstrap";
import DateBox from "../components/DateBox";
import Page from "../components/Page";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import article, {Article} from "../service/article";
import useAsync, {isLoading} from "../hooks/async";
import Loader from "../components/Loader";
import parse from "html-react-parser";
import DownloadLink from "../components/DownloadLink";
import {useAppContext} from "../contexts/App/appContext";
import ImageGallery from "../components/ImageGallery";

interface Params {
  slug: string
}

const NewsDetail = () => {
  const {t, i18n} = useTranslation();
  const params = useParams<Params>();
  const {state} = useAppContext();

  const promise = useCallback(() => {
    return article.getArticle(params.slug, i18n.language);
  }, [i18n.language, params.slug]);

  const asyncState = useAsync<Article>(promise);

  const fileUploads = asyncState.data?.uploads?.filter((f) => !f.file.match(/.(jpg|jpeg|png|gif)$/i)).map((f) =>
    <DownloadLink key={f.file} name={f.description || t('link.attachmentFallback')} link={f.file}/>)
  const imageUploads = asyncState.data?.uploads?.filter((f) => f.file.match(/.(jpg|jpeg|png|gif)$/i))

  return (
    <Page titleAlt="News detail" titleImage="/img/page-titles/news-detail">
      <Container className="px-lg-7 px-4">
        <Loader isLoading={isLoading(asyncState)}/>
        <Row className="pb-4">
          <Col>
            {state.user && state.user.can_edit &&
            <Link to={`/news/${params.slug}/edit`} className="btn btn-primary float-end"> {t('link.update')} </Link>
            }
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <DateBox className="float-lg-end mb-4" date={asyncState.data?.created_at}/>
          </Col>
          <Col xs={12} md={6} className="text-start">
            <h4>
              {asyncState.data?.title}
            </h4>
          </Col>
        </Row>
        <Row className="mt-3 px-lg-6">
          <Col xs={12} md={6}>
            <img src={asyncState.data?.image} alt="news detail" className="float-end img-fluid" width="400px"/>
          </Col>
          <Col xs={12} md={6} className="text-start">
            {asyncState.data?.body && parse(asyncState.data?.body)}
          </Col>
        </Row>
        <hr/>
        {fileUploads}
        { imageUploads && <ImageGallery images={imageUploads} showDelete={false}/>}
        <div className="text-center pt-5 pb-5">
          <Link to="/news"
                className="btn btn-outline-secondary p-3 text-uppercase h2 spaced-link px-5"> {t("link.allNews")} </Link>
        </div>
      </Container>
    </Page>
  )
}

export default NewsDetail
