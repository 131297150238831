import UploadFile from "../UploadFiles";
import Error from "../Error";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {SubmitHandler, useForm} from "react-hook-form";
import {ArticleInput} from "../ArticleForm";
import {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {Article} from "../../service/article";

interface Props {
  onSubmit: (a: ArticleInput) => Promise<void>
  article?: Article
  handleDelete?: (id: number) => Promise<void>;
}

const PressForm: FunctionComponent<Props> = ({onSubmit, article, handleDelete}) => {
  const {t} = useTranslation();
  const {handleSubmit, register, formState: {errors}} = useForm<ArticleInput>();
  const [isLoading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [err, setErr] = useState<string | null>(null);

  const submitHandler: SubmitHandler<ArticleInput> = ({short, title}) => {
    if (file || article?.image) {
      setLoading(true)
      onSubmit({image: file, short, title, body: "..."}).then(() => setLoading(false))
    } else {
      setErr(t("validationError.noFile"))
    }
  }

  const onDelete = (id: number) => {
    setLoading(true)
    if (handleDelete) {
      return handleDelete(id).then(() => {
        setLoading(false)
      }).catch((e) => {
        setLoading(false);
        setErr(e.message)
      })
    }
    return Promise.resolve();
  }

  return (
    <Container>
      {article && article.id && handleDelete &&
      <Row>
          <Col className="text-end">
              <Button variant="danger" onClick={() => onDelete(article.id)}>{t('link.delete')}</Button>
          </Col>
      </Row>
      }
      <Row>
        <Col>
          <UploadFile onChange={(f) => setFile(f[0])} multiple={false} label={t("form.article.Image")} zone={true} formats="image/*" defaultImage={article?.image}/>
          <form onSubmit={handleSubmit(submitHandler)}>
            <Error error={err}/>
            <Form.Group className="mb-3" controlId="email">
              <Form.Control
                type="text"
                defaultValue={article ? article.title : ""}
                placeholder={t("form.article.Title")}
                {...register("title", {
                  value: article ? article.title : "",
                  required: {
                    value: true,
                    message: t("form.validationMessages.required")
                  },
                  minLength: {
                    value: 3,
                    message: t("form.validationMessages.min", {field: t("form.article.Title")})
                  }
                })}/>
              <Form.Text className="text-danger">
                {errors.title && errors.title.message}
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <Form.Control
                type="text"
                defaultValue={article ? article.short : ""}
                placeholder={t("form.media.PressLink")}
                {...register("short", {
                  value: article ? article.short : "",
                  required: {
                    value: true,
                    message: t("form.validationMessages.required")
                  },
                  minLength: {
                    value: 3,
                    message: t("form.validationMessages.min", {field: t("form.media.PressLink")})
                  }
                })}/>
              <Form.Text className="text-danger">
                {errors.title && errors.title.message}
              </Form.Text>
            </Form.Group>
            <Form.Group className="text-end">
              <Button type="submit" disabled={isLoading}>{article ? t('link.update') : t('link.create')}</Button>
            </Form.Group>
          </form>
        </Col>
      </Row>
    </Container>
  )
}

export default PressForm
