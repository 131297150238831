import axios, {AxiosResponse} from 'axios';
import {Upload} from "../service/upload";

export function uploadImage(file: File, description?: string) {
    const data = new FormData();
    data.append('file', file, file.name)
    if (description) {
        data.append('description', description)
    }

    return axios.post('/api/v1/uploads/pages/media', data).then((response: AxiosResponse<Upload>) => {
        return {
            data: {
                link: response.data ? response.data.file : null,
            },
            ...response.data
        };
    });
}
