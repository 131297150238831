import Page from "../components/Page";
import {Col, Container, Row} from "react-bootstrap";
import VideoMedia from "../components/VideoMedia";
import {Link} from "react-router-dom";
import React from "react";
import {useAppContext} from "../contexts/App/appContext";
import {useTranslation} from "react-i18next";
import {Background, Parallax} from "react-parallax";
import PhotoMedia from "../components/PhotoMedia";
import PressMedia from "../components/PressMedia";

const Media = () => {
  const {state} = useAppContext();
  const {t} = useTranslation();
  return (
    <Page titleImage="/img/page-titles/media" titleAlt="media">
      <div>
        <Container>
          <Row>
            <Col xs={12}>
              {state.user && state.user.can_edit &&
              <Link to="/media/photo/upload" className="btn btn-primary float-end"> {t('link.create')} </Link>
              }
            </Col>
            <Col xs={12}>
              <h5>{t('titles.Photo')}</h5>
              <PhotoMedia/>
              <div className="text-center pt-5 pb-5">
                <Link to="/media/photo"
                      className="btn btn-outline-secondary p-3 text-uppercase h2 spaced-link px-5"> {t("link.allPhotos")} </Link>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col>
              <hr/>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col xs={12}>
              {state.user && state.user.can_edit &&
              <Link to="/media/video/upload" className="btn btn-primary float-end"> {t('link.create')} </Link>
              }
            </Col>
            <Col xs={12}>
              <h5>{t('titles.Video')}</h5>
              <VideoMedia/>
              <div className="text-center pt-5 pb-5">
                <Link to="/media/video"
                      className="btn btn-outline-secondary p-3 text-uppercase h2 spaced-link px-5"> {t("link.allVideos")} </Link>
              </div>
            </Col>
          </Row>
        </Container>
        <Parallax bgImageAlt="concert middle image" strength={400} className="concert-image title-image mb-6 mt-6"
                  style={{width: '100%', height: '500px'}}>
          <Background className="parallax-bg">
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundImage: "url('/img/photo-parallax-media.jpg')",
                backgroundPosition: 'top center'
              }}
            />
          </Background>
        </Parallax>
        <Container>
          <Row>
            <Col xs={12}>
              {state.user && state.user.can_edit &&
              <Link to="/media/press/create" className="btn btn-primary float-end"> {t('link.create')} </Link>
              }
            </Col>
            <Col xs={12}>
              <h5>{t('titles.Press')}</h5>

              <PressMedia />
            </Col>
          </Row>
        </Container>
      </div>
    </Page>
  )
}

export default Media
