import React, {Suspense, useCallback} from 'react';
import Footer from "./components/Footer";
import MainNav from "./components/MainNav";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from "./views/Home";
import AllNews from "./views/AllNews";
import NewsDetail from "./views/NewsDetail";
import About from "./views/About";
import Concerts from "./views/Concerts";
import Compositions from "./views/Compositions";
import Discography from "./views/Discography";
import Contact from "./views/Contact";
import CompositionDetail from "./views/CompositionDetail";
import ProtectedRoute from "./components/Route";
import Login from "./views/Login";
import Loader from "./components/Loader";
import CreateArticle from "./views/CreateArticle";
import UserService, {User} from "./service/user";
import {SET_USER_INFO, useAppContext} from "./contexts/App/appContext";
import useAsync, {isLoading} from "./hooks/async";
import CreateConcert from "./views/CreateConcert";
import CreateDiscography from "./views/CreateDiscography";
import CreateArticleCategory from "./views/CreateArticleCategory";
import Media from "./views/Media";
import CreateComposition from "./views/CreateComposition";
import EditAbout from "./views/EditAbout";
import EditArticle from "./views/EditArticle";
import EditConcert from "./views/EditConcert";
import EditComposition from "./views/EditComposition";
import EditDiscography from "./views/EditDiscography";
import CreateVideoUpload from "./views/CreateVideoUpload";
import CreatePhotoUpload from "./views/CreatePhotoUpload";
import MediaVideos from "./views/MediaVideos";
import MediaPhotos from "./views/MediaPhotos";
import EditVideoUpload from "./views/EditVideoUpload";
import CreatePress from "./views/CreatePress";
import EditPress from "./views/EditPress";
import EditArticleCategory from "./views/EditArticleCategory";

function App() {
  const {state, dispatch} = useAppContext();

  const promise = useCallback(() => {
    if (state.user === null && state.token !== null) {
      return UserService.userInfo().then((userInfo) => {
        dispatch({
          type: SET_USER_INFO,
          value: userInfo
        })
      });
    }

    return Promise.resolve();
  }, [dispatch, state.token, state.user]);

  const asyncState = useAsync<User | void>(promise);

  return (
    <div className="App">
      <Router>
        <Suspense fallback={<Loader isLoading={true}/>}>
          <Loader isLoading={isLoading<User | void>(asyncState)}/>
          {!isLoading<User | void>(asyncState) && (
            <div>
              <MainNav/>
              <Switch>
                <Route exact path="/">
                  <Home/>
                </Route>
                <ProtectedRoute neededPerm="admin" path="/news/create">
                  <CreateArticle/>
                </ProtectedRoute>
                <ProtectedRoute neededPerm="admin" path="/concerts/create">
                  <CreateConcert/>
                </ProtectedRoute>
                <ProtectedRoute neededPerm="admin" path="/concerts/:slug/edit">
                  <EditConcert/>
                </ProtectedRoute>
                <ProtectedRoute neededPerm="admin" path="/discography/create">
                  <CreateDiscography/>
                </ProtectedRoute>
                <ProtectedRoute neededPerm="admin" path="/compositions/create">
                  <CreateArticleCategory/>
                </ProtectedRoute>
                <ProtectedRoute neededPerm="admin" path="/about/:slug/edit">
                  <EditAbout/>
                </ProtectedRoute>
                <ProtectedRoute neededPerm="admin" path="/news/:slug/edit">
                  <EditArticle/>
                </ProtectedRoute>
                <ProtectedRoute neededPerm="admin" path="/discography/:slug/edit">
                  <EditDiscography/>
                </ProtectedRoute>
                <ProtectedRoute neededPerm="admin" path="/composition/:name/:slug/edit">
                  <EditArticleCategory/>
                </ProtectedRoute>
                <ProtectedRoute neededPerm="admin" path="/compositions/:category/:slug/edit">
                  <EditComposition/>
                </ProtectedRoute>
                <ProtectedRoute neededPerm="admin" path="/media/video/:id/edit">
                  <EditVideoUpload/>
                </ProtectedRoute>
                <ProtectedRoute neededPerm="admin" path="/media/press/:slug/edit">
                  <EditPress/>
                </ProtectedRoute>
                <ProtectedRoute neededPerm="admin" path="/media/press/create">
                  <CreatePress/>
                </ProtectedRoute>
                <ProtectedRoute neededPerm="admin" path="/media/video/upload">
                  <CreateVideoUpload/>
                </ProtectedRoute>
                <ProtectedRoute neededPerm="admin" path="/media/photo/upload">
                  <CreatePhotoUpload/>
                </ProtectedRoute>
                <Route path="/news/:slug">
                  <NewsDetail/>
                </Route>
                <Route path="/news">
                  <AllNews/>
                </Route>
                <Route exact path="/about">
                  <About/>
                </Route>
                <Route path="/concerts">
                  <Concerts/>
                </Route>
                <ProtectedRoute neededPerm="admin" path="/compositions/:category/create">
                  <CreateComposition/>
                </ProtectedRoute>
                <Route path="/compositions/:category/:slug">
                  <CompositionDetail/>
                </Route>
                <Route path="/compositions">
                  <Compositions/>
                </Route>
                <Route path="/discography">
                  <Discography/>
                </Route>
                <Route path="/contact">
                  <Contact/>
                </Route>
                <Route path="/media/video">
                  <MediaVideos />
                </Route>
                <Route path="/media/photo">
                  <MediaPhotos />
                </Route>
                <Route path="/media">
                  <Media />
                </Route>
                <ProtectedRoute neededPerm="notLogged" path="/login">
                  <Login/>
                </ProtectedRoute>
              </Switch>
              <Footer/>
            </div>
          )
          }
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
