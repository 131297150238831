import Page from "../components/Page";
import NewsForm from "../components/ArticleForm";
import ArticleService from "../service/article";
import {useParams} from "react-router-dom";

interface Params {
  category: string
}

const CreateArticle = () => {
  const params = useParams<Params>();

  return (
    <Page titleImage="/img/page-titles/create/create-compositions" titleAlt={"Create composition"}>
      <NewsForm
        handleChange={(input) => {
          return ArticleService.create(input, "", params.category)
        }}
        listURL="/compositions"
        showImageUpload={true}
        showUploads={false}
        showShort={true}
      />
    </Page>
  )
}

export default CreateArticle;
