import Page from "../components/Page";
import {ArticleInput} from "../components/ArticleForm";
import {useHistory} from "react-router-dom";
import PressForm from "../components/PressForm";
import article from "../service/article";


const CreatePhotoUpload = () => {
  const history = useHistory();

  const onSubmit = (a: ArticleInput) => {
    return article.create(a, "", "press").then(() => {
      history.push("/media")
    })
  }

  return (
    <Page titleImage="/img/page-titles/create/create-media" titleAlt={"Create Media"}>
      <PressForm
        onSubmit={onSubmit}
      />
    </Page>
  )
}

export default CreatePhotoUpload;
