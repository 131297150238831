import React from 'react';
import dayjs from "dayjs";

interface Props {
  className?: string
  date?: string
}

const DateBox:React.FunctionComponent<Props> = ({className, date}) => {
  return (
    <div className={`date-box ${className}`}>
      <div className="date-box-text">
        {date && dayjs(date).format('DD MMM YYYY').toString()}
      </div>
    </div>
  )
}

export default DateBox
