import React, {useCallback} from "react";
import Page from "../components/Page";
import {Col, Container, Row} from "react-bootstrap";
import ConcertEvent from "../components/ConcertEvent";
import Paginator from "../components/Pagination";
import {Background, Parallax} from "react-parallax";
import {Link, useLocation} from "react-router-dom";
import article, {Article} from "../service/article";
import useAsync, {isLoading} from "../hooks/async";
import {Paginated} from "../service/types";
import {useAppContext} from "../contexts/App/appContext";
import {useTranslation} from "react-i18next";
import Loader from "../components/Loader";

const Concerts = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1");
  const {state} = useAppContext();
  const {t, i18n} = useTranslation();

  const futureConcerts = useAsync<Paginated<Article>>(useCallback(() => article.getArticles({p: 1, s: 999}, i18n.language, "concerts", 'future'), [i18n.language]));

  const promise = useCallback(() => {
    return article.getArticles({p: page, s: 6}, i18n.language, "concerts", 'past');
  }, [page, i18n.language]);

  const asyncState = useAsync<Paginated<Article>>(promise);

  function createGroupItem(concert: Article, index: number) {
    const last = (asyncState.data && asyncState.data.data.length - 1 === index) || false;
    return <ConcertEvent key={concert.id} concert={concert} last={last}/>
  }

  let firstGroup: React.ReactElement[] = [];
  let secondGroup: React.ReactElement[] = [];

  if (asyncState.data) {
    if (futureConcerts && futureConcerts.data) {
      firstGroup = futureConcerts.data.data?.reverse()?.map(createGroupItem);
    }

    secondGroup = asyncState.data.data.map(createGroupItem);
  }
  const titleImage = '/img/page-titles/past-concerts'
  return (
    <Page titleImage={page === 1 ? '/img/page-titles/upcoming-concerts' : titleImage } titleAlt="upcoming concerts">
      <div>
          <Loader isLoading={isLoading(asyncState)}/>
          { page === 1 && (
          <>
        <Container>
          <Row>
            <Col>
              {state.user && state.user.can_edit &&
              <Link to="/concerts/create" className="btn btn-primary float-end"> {t('link.create')} </Link>
              }
            </Col>
          </Row>
          {firstGroup}
        </Container>
        <Parallax bgImageAlt="concert middle image" strength={400} className="concert-image title-image mb-6"
                  style={{width: '100%', height: '450px'}}>
          <Background className="parallax-bg">
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundImage: "url('/img/photo-parallax-concerts.jpg')",
                backgroundPosition: 'top center',
                backgroundSize: 'cover',
                marginTop: '120px'
              }}
            />
          </Background>
        </Parallax>
          <div className={`text-center pt-5 pb-5`}>
            <img src={`${state.lang === "sk" ? titleImage + "-sk" : titleImage}.svg`} alt={'past concerts'} width="40%" className="page-title"/>
          </div>
        </>
        )}
        <Container className="pb-7">
          {secondGroup}
          <Paginator page={page} totalPages={asyncState.data?.total_pages}/>
        </Container>
      </div>
    </Page>
  );
}

export default Concerts
