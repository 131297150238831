import Page from "../components/Page";
import {Alert, Col, Container, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SubmitHandler, useForm} from "react-hook-form";
import axios from "axios";
import Error from "../components/Error";
import Loader from "../components/Loader";

interface ContactInfo {
  name: string
  email: string
  message: string
  subject: string
}

const Contact = () => {
  const {t} = useTranslation();
  const {handleSubmit, register, formState: {errors}} = useForm<ContactInfo>();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const sendMail: SubmitHandler<ContactInfo> = (contactInfo) => {
    setError(null);
    axios.post("/api/v1/contact", contactInfo).then(() => {
      setSuccess(true);
    }).catch(e => setError(e.message || e))
  }

  useEffect(() => {
    let timer = window.setTimeout(() => {
      setLoading(false)
    }, 1000);
    return () => {
      window.clearTimeout(timer);
    }
  })

  return (
    <Page titleImage="/img/page-titles/contact" titleAlt="contact">
      <Container className="pb-5 pt-4">
        <Loader isLoading={isLoading}/>
        <div className="text-center">
            <h6 className="text-secondary">+421 908 338 442, contact@ivan-buffa.com</h6>
        </div>
        <hr/>
        <div className="text-center">
          <Col md={8} className="m-auto">
            <h4 className="pb-5">{t('titles.ContactForm')}</h4>
            <form onSubmit={handleSubmit(sendMail)}>
              <Error error={error}/>
              <Alert variant="success" show={success}>
                {t("form.contact.Success")}
              </Alert>
              {!success && (
                <div>
                  <Row>
                    <Col md={6}>
                      <Form.Control
                        type="text"
                        placeholder={t("form.contact.Name")}
                        {...register("name", {
                          required: {
                            value: true,
                            message: t("form.validationMessages.required")
                          },
                          minLength: {
                            value: 3,
                            message: t("form.validationMessages.min", {field: t("form.contact.Name")})
                          }
                        })}
                      />
                      <Form.Text className="text-danger">
                        {errors.name && errors.name.message}
                      </Form.Text>
                    </Col>
                    <Col md={6}>
                      <Form.Control
                        type="email"
                        placeholder={t("form.contact.Email")}
                        {...register("email", {
                          required: {
                            value: true,
                            message: t("form.validationMessages.required")
                          },
                          minLength: {
                            value: 3,
                            message: t("form.validationMessages.min", {field: t("form.contact.Email")})
                          }
                        })}
                      />
                      <Form.Text className="text-danger">
                        {errors.email && errors.email.message}
                      </Form.Text>
                    </Col>
                    <Col xs={12}>
                      <Form.Control
                        type="text"
                        placeholder={t("form.contact.Subject")}
                        {...register("subject", {
                          required: {
                            value: true,
                            message: t("form.validationMessages.required")
                          },
                          minLength: {
                            value: 3,
                            message: t("form.validationMessages.min", {field: t("form.contact.Subject")})
                          }
                        })
                        }
                      />
                      <Form.Text className="text-danger">
                        {errors.subject && errors.subject.message}
                      </Form.Text>
                    </Col>
                    <Col xs={12}>
                      <Form.Control
                        as="textarea"
                        style={{height: '150px', width: '100%', resize: 'none'}}
                        placeholder={t("form.contact.Message")}
                        {...register("message", {
                          required: {
                            value: true,
                            message: t("form.validationMessages.required")
                          },
                          minLength: {
                            value: 3,
                            message: t("form.validationMessages.min", {field: t("form.contact.Message")})
                          }
                        })
                        }
                      />
                      <Form.Text className="text-danger">
                        {errors.message && errors.message.message}
                      </Form.Text>
                    </Col>
                  </Row>
                  <div className="text-end pt-5 pb-5">
                    <button type="submit"
                            className="btn btn-outline-secondary p-3 text-uppercase h2 spaced-link px-5"> {t('link.send')}
                    </button>
                  </div>
                </div>
              )}
            </form>
          </Col>
        </div>
      </Container>
    </Page>
  )
}

export default Contact
