import axios from 'axios';
import {PagintaionFilter} from "../service/types";

export const GALLERY_TYPE = 'media';
export const PAGES_TYPE = 'pages';

interface UploadCategory {
  name: string
  subPath: string
  description: string
  type: string
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list(type: string) {
    return axios.get(`/api/v1/uploads/${type}`).then((r) => r.data);
  },
  listUploads(pagination: PagintaionFilter, type: string, category: string) {
    const { s, p } = pagination;
    return axios.get(`/api/v1/uploads/${type}/${category}`, {
      params: {
        s,
        p,
      }
    }).then(r => r.data);
  },
  createCategory(data: UploadCategory) {
    const {name, subPath, description, type} = data;

    return axios.post(`/api/v1/uploads/${type}`, {
      name,
      description,
      subPath,
    });
  },
  upload(data: FormData, category: string, type = PAGES_TYPE) {
    return axios.post(`/api/v1/uploads/${type}/${category}`, data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }).then((response) => {
      return {
        data: {
          link: response.data ? response.data.file : null,
        },
        ...response.data
      };
    });
  },
  getUpload(category: string, type: string, id: number) {
    return axios.get(`/api/v1/uploads/${type}/${category}/${id}`).then((r) => r.data)
  },
  edit(category: string, type: string, id: number, description: string) {
    return axios.put(`/api/v1/uploads/${type}/${category}/${id}`, {description}).then((r) => r.data)
  },
  delete(category: string, type: string, id: number) {
    return axios.delete(`/api/v1/uploads/${type}/${category}/${id}`).then((r) => r.data)
  }
};
