import {Col, Row} from "react-bootstrap";
import React, {useEffect, useRef} from "react";
import {SET_MP3_PLAYING, useAppContext} from "../../contexts/App/appContext";

interface Props {
  src: string,
  name: string
}

const Player: React.FunctionComponent<Props> = ({src, name}) => {
  const {dispatch, state} = useAppContext();
  const playerIcon = state.mp3Playing === src ? 'bi-pause-fill' : 'bi-play-fill';
  const audio = useRef<HTMLAudioElement | null>(null);
  useEffect(() => {
    if (state.mp3Playing !== src && audio.current) {
      if (audio.current?.duration > 0 && !audio.current.paused) {
        audio.current.pause();
      }
    }
  }, [src, state.mp3Playing])

  return (

    <Row className="mb-3">
      <Col xs={2} className="text-center" onClick={() => {
        if (audio.current) {
          if (state.mp3Playing === src) {
            dispatch({type: SET_MP3_PLAYING, value: ""})
            audio.current.pause();
          } else {
            dispatch({type: SET_MP3_PLAYING, value: src})
            audio.current.play();
          }
        }
      }}>
        <i className={`text-secondary h4 ${playerIcon}`}/>
        <audio src={src} ref={audio}/>
      </Col>
      <Col xs={10}>
        {name}
      </Col>
    </Row>
  )
}

export default Player;
