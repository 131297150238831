import axios, {AxiosResponse} from 'axios';
import {User} from "../service/user";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login(email: string, password: string) {
    return axios.post(`/api/v1/auth/login`, {email, password}).then((response) => response.data);
  },
  info() {
    return axios.get(`/api/v1/auth/user`).then((response: AxiosResponse<User>) => response.data);
  },
  forgotPassword(email: string) {
    return axios.post(`/api/v1/auth/forgot-password`, {email});
  },
  resetPassword(password: string, password_confirmation: string, token: string) {
    return axios.post(`/api/v1/auth/reset-password/${token}`, {password, password_confirmation});
  }
}
