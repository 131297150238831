import Page from "../components/Page";
import {useTranslation} from "react-i18next";
import UploadForm from "../components/UploadForm";
import {BlobWithDescription} from "../components/ArticleForm";
import upload from "../service/upload";
import {useHistory} from "react-router-dom";


const CreatePhotoUpload = () => {
  const {t} = useTranslation();
  const history = useHistory();

  const onSubmit = (u: BlobWithDescription) => {
    //@ts-ignore
    return upload.uploadOne(u.file, "photo", u.description).then(() => {
      history.push("/media")
    })
  }

  return (
    <Page titleImage="/img/page-titles/create/create-media" titleAlt={"Create Media"}>
      <UploadForm
        onSubmit={onSubmit}
        label={t("form.media.Photo")}
        formats="image/*"
        withDescription={true}
      />
    </Page>
  )
}

export default CreatePhotoUpload;
