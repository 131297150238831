import {Pagination} from "react-bootstrap";
import {FunctionComponent} from "react";
import {useHistory} from "react-router-dom";

interface Props {
  page?: number
  totalPages?: number
}

const Paginator: FunctionComponent<Props> = ({page, totalPages}) => {

  const paginationItems = [];
  const history = useHistory();
  if (totalPages) {
    for (let i = 1; i <= totalPages; i++) {
      if (totalPages > 5 && i > 4 && totalPages !== i) {
        continue
      }

      let paginationItem = <Pagination.Item className={page === i ? "active" : ""} onClick={() => history.push(`${history.location.pathname}?page=${i}`)}>{i}</Pagination.Item>
      if (totalPages > 5 && i === 4) {
        paginationItem = <Pagination.Ellipsis disabled/>
      }

      paginationItems.push(paginationItem)
    }
  }

  return (
    <div className="text-xs-center pt-5 pb-5">
      {page && totalPages && totalPages > 1 && <Pagination className="pag justify-content-center">
          <Pagination.Prev onClick={() => history.push(`${history.location.pathname}?page=${page-1}`)}/>
          {paginationItems}
          <Pagination.Next onClick={ () => history.push(`${history.location.pathname}?page=${page+1}`)}/>
      </Pagination>
      }
    </div>
  );
}

export default Paginator
