import Page from "../components/Page";
import {Col, Container, Row} from "react-bootstrap";
import Composition from "../components/Composition";
import {Link} from "react-router-dom";
import {useAppContext} from "../contexts/App/appContext";
import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useState} from "react";
import article, {Article, ArticleCategory} from "../service/article";
import useAsync, {isLoading} from "../hooks/async";
import Loader from "../components/Loader";
import {INTERNAL_CATEGORIES} from "../api/articles";
import {Paginated} from "../service/types";

const Compositions = () => {
  const [categories, setCategories] = useState<ArticleCategory[]>([]);
  const {state} = useAppContext();
  const {t, i18n} = useTranslation();

  const promise = useCallback(() => {
    return article.getArticleCategories(i18n.language);
  }, [i18n.language]);

  const asyncState = useAsync<ArticleCategory[]>(promise);

  useEffect(() => {
    if (asyncState.data) {
      setCategories(asyncState.data.filter((category) => INTERNAL_CATEGORIES.indexOf(category.slug) === -1));
    }
  }, [asyncState.data]);


  const promiseArticles = useCallback(() => {
    return Promise.all(categories.map((category) => article.getArticles({p: 1, s: 9999}, "", category.slug)));
  }, [categories]);

  const asyncStateArticles = useAsync<Paginated<Article>[]>(promiseArticles);

  const compositions = categories.map((category, i) => {
    let compositions: Paginated<Article> = {data: [], page: 1, page_size: 9999, total: 0, total_pages: 1};

    if (asyncStateArticles.data) {
      compositions = asyncStateArticles.data.find((composition) => composition.data.length && composition.data[0].article_category_id === category.id) || compositions;
    }
    return (
      <div key={category.slug}>
        <Container className="px-lg-7 px-4">
          <Row>
            <Col>
              {state.user && state.user.can_edit &&
              (
                <>
                <Link to={`/compositions/${category.slug}/create`}
                    className="btn btn-primary float-end"> {t('link.create')} </Link>
                <Link to={`/composition/${category.slug}/${category.slug}/edit`}
                  className="btn btn-primary float-end me-2"> {t('link.update')} </Link>
                </>
              )
              }
            </Col>
          </Row>

          <h4
            className="pb-5">{category.name}</h4>

          <Row>
            {compositions.data.map((composition, index) => <Composition
              composition={composition}
              key={`${category.slug}-${composition.id}`}
              last={index >= compositions.data.length - (compositions.data.length % 2 === 0 ? 2 : 1)}
              category_slug={category.slug}/>)}
          </Row>
        </Container>
        {/*// REMOVE last hr*/}
        {categories.length - 1 > i && <Container>
            <hr/>
        </Container>
        }
      </div>
    );
  })

  return (
    <Page titleImage="/img/page-titles/compositions" titleAlt="compositions">
      <div>
        <Container>
          <Loader isLoading={isLoading(asyncState) || isLoading(asyncStateArticles)}/>
          <div className="row pb-4">
            <div className="col">
              {state.user && state.user.can_edit &&
              <Link to="/compositions/create" className="btn btn-primary float-end"> {t('link.createCategory')} </Link>
              }
            </div>
          </div>
          {compositions}
        </Container>
      </div>
    </Page>
  )
}

export default Compositions
