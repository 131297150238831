import axios, {AxiosResponse} from 'axios';
import {ArticleCategory, ArticleRequest} from "../service/article";
import {PagintaionFilter} from "../service/types";
import dayjs from "dayjs";

export const INTERNAL_CATEGORIES = ["news", "concerts", "discography", "news-sk", "concerts-sk", "discography-sk", "about", "about-sk", "press"]

const article = {
    create(data: ArticleRequest, category: string) {
        return axios.post(`/api/v1/articles/${category}`, data).then((reponse) => reponse.data)
    },
    edit(data: ArticleRequest, category: string) {
        return axios.put(`/api/v1/articles/${category}/${data.id}`, data).then((reponse) => reponse.data)
    },
    detail(slug: string, category: string) {
        return axios.get(`/api/v1/articles/${category}/${slug}`).then((response) => response.data);
    },
    delete(id: number, category: string) {
        return axios.delete(`/api/v1/articles/${category}/${id}`).then((response) => response.data);
    },
    list(filter: PagintaionFilter, category: string, createAtFilter?: 'past'|'future') {
        const createdFilter: {
            createdBefore?: string,
            createdAfter?: string
        } = {}
        if (createAtFilter === 'future') createdFilter.createdAfter = dayjs().format('YYYY-MM-DD')
        if (createAtFilter === 'past') createdFilter.createdBefore = dayjs().format('YYYY-MM-DD')
        return axios.get(`/api/v1/articles/${category}`, {
            params: {
                ...filter,
                ...createdFilter
            }
        }).then((response) => response.data);
    },
    listCategories(lang: string) {
        return axios.get(`/api/v1/articles`, {params: {lang}}).then((response: AxiosResponse<ArticleCategory[]>) => response.data);

    },
    // TODO: improve
    createCategory(data: any) {
        return axios.post(`/api/v1/articles`, data).then((reponse) => reponse.data)
    },
    editCategory(slug: string, data: any) {
        return axios.put(`/api/v1/articles/${slug}`, data).then((reponse) => reponse.data)
    },
}

export default article
