import Page from "../components/Page";
import {useTranslation} from "react-i18next";
import UploadForm from "../components/UploadForm";
import {BlobWithDescription} from "../components/ArticleForm";
import upload, {Upload} from "../service/upload";
import {useHistory, useParams} from "react-router-dom";
import React, {useCallback} from "react";
import useAsync, {isLoading} from "../hooks/async";
import Loader from "../components/Loader";

interface Params {
  id: string
}

const EditVideoUpload = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const params = useParams<Params>();
  let id = 0
  try {
    id = parseInt(params.id)
  } catch (e) {
    history.push("/media")
  }

  const promise = useCallback(() => {
    return upload.getUpload("media", "video", id);
  }, [id]);

  const asyncState = useAsync<Upload>(promise);

  const onSubmit = (u: BlobWithDescription) => {
    return upload.edit("media", "video", id, u.description || "...").then(() => {
      history.push("/media")
    })
  }

  return (
    <Page titleImage="/img/page-titles/edit/edit-media" titleAlt={"Edit Media"}>
      <div>
        <Loader isLoading={isLoading(asyncState)}/>
        {asyncState.data && <UploadForm
            onSubmit={onSubmit}
            label={t("form.media.Video")}
            formats="video/*"
            upload={asyncState.data}
            withFile={false}
        />
        }
      </div>
    </Page>
  )
}

export default EditVideoUpload;
