import Page from "../components/Page";
import {Col, Container, Row} from "react-bootstrap";
import DiscographyAlbum from "../components/DiscographyAlbum";
import {Link, useLocation} from "react-router-dom";
import React, {useCallback} from "react";
import {useAppContext} from "../contexts/App/appContext";
import {useTranslation} from "react-i18next";
import article, {Article} from "../service/article";
import useAsync, {isLoading} from "../hooks/async";
import {Paginated} from "../service/types";
import Loader from "../components/Loader";


const Discography = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1");
  const {state} = useAppContext();
  const {t, i18n} = useTranslation();

  const promise = useCallback(() => {
    return article.getArticles({p: page, s: 99999}, i18n.language, "discography");
  }, [page, i18n.language]);

  const asyncState = useAsync<Paginated<Article>>(promise);

  const albums = asyncState.data?.data.map((album, index) => <DiscographyAlbum key={album.id} album={album}
                                                                               last={(asyncState.data && asyncState.data.data.length === index - 1) || false}/>)

  return (
    <Page titleImage="/img/page-titles/discography" titleAlt="Discography">
      <Container className="pb-3 pt-4">
        <Loader isLoading={isLoading(asyncState)}/>
        <Row className="pb-4">
          <Col>
            {state.user && state.user.can_edit &&
            <Link to="/discography/create" className="btn btn-primary float-end"> {t('link.create')} </Link>
            }
          </Col>
        </Row>
        {albums}
      </Container>
    </Page>
  )
}

export default Discography
