import Page from "../components/Page";
import {useTranslation} from "react-i18next";
import UploadForm from "../components/UploadForm";
import {BlobWithDescription} from "../components/ArticleForm";
import upload from "../service/upload";
import {useHistory} from "react-router-dom";


const CreateVideoUpload = () => {
  const {t} = useTranslation();
  const history = useHistory();

  const onSubmit = (u: BlobWithDescription) => {
    return upload.uploadOne(null, "video", u.description, u.youtube_suffix).then(() => {
      history.push("/media")
    })
  }

  return (
    <Page titleImage="/img/page-titles/create/create-media" titleAlt={"Create Media"}>
      <UploadForm
        onSubmit={onSubmit}
        label={t("form.media.Video")}
        formats="video/*"
      />
    </Page>
  )
}

export default CreateVideoUpload;
