import {useState} from "react";
import {
  Dropzone,
  FileItem,
  FileItemContainer,
  FullScreenPreview,
  InputButton
} from "dropzone-ui";
import {FileValidated} from "dropzone-ui/build/components/dropzone/components/utils/validation.utils";

interface Props {
  onChange: (files: File[]) => void
  multiple?: boolean
  label: string
  zone?: boolean
  defaultImage?: string
  formats?: string
}

const UploadFiles: React.FunctionComponent<Props> = (
  {
    onChange,
    multiple = false,
    label,
    zone = false,
    defaultImage,
    formats = "*"
  }) => {
  const [files, setFiles] = useState<FileValidated[]>([]);
  const [imgSource, setImgSource] = useState<string | undefined>("");
  const updateFiles = (incommingFiles: FileValidated[]) => {
    setFiles(incommingFiles);
    onChange(incommingFiles.map((f) => f.file));
  };
  const onDelete = (id: number | string) => {
    const newState = files.filter((x) => x.id !== id);
    setFiles(newState);
    onChange(newState.map((f) => f.file));
  };

  return (
    <div className="pt-4 pb-4">
      {zone && defaultImage && (
        <div className="text-center mb-4">
          <img src={defaultImage} className="img-thumbnail w-50" alt="preview"/>
        </div>
      )}
      {zone && (<Dropzone
          onChange={updateFiles}
          value={files}
          maxFiles={1}
          accept={formats}
          label={label}
          behaviour="replace"
          header={false}
        >
          {files.map((file) => (
            <FileItem {...file} onDelete={onDelete} preview info key={`file-${file.id}`}/>
          ))}
        </Dropzone>
      )}
      {!zone && (
        <div>
          <InputButton
            onChange={updateFiles}
            label={label}
            accept={formats}
            multiple={multiple}
          />
          <FileItemContainer view="list">
            {files.map((file) => (
              <FileItem
                {...file}
                key={`files-${file.id}`}
                onDelete={onDelete}
                onSee={(src: string) => {
                  setImgSource(src);
                }}
                preview
                info
                hd
              />
            ))}
            <FullScreenPreview
              imgSource={imgSource}
              openImage={!!imgSource}
              onClose={() => setImgSource(undefined)}
            />
          </FileItemContainer>
        </div>
      )}
    </div>
  );
}

export default UploadFiles
