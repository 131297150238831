import {Parallax, Background} from 'react-parallax';
import React, {useCallback} from 'react';
import {Container} from "react-bootstrap";
import NewsList from "../components/NewsList";
import {useTranslation} from "react-i18next";
import article, {Article} from "../service/article";
import useAsync, {isLoading} from "../hooks/async";
import Loader from "../components/Loader";
import {Paginated} from "../service/types";
import {Link} from "react-router-dom";

function Home() {
  const {t, i18n} = useTranslation();

  const promise = useCallback(() => {
    return article.getArticles({p: 1, s: 6}, i18n.language);
  }, [i18n.language]);

  const asyncState = useAsync<Paginated<Article>>(promise);

  return (
    <div className="home">
      <Parallax bgImageAlt="title image" strength={900} className="title-image">
        <Background className="parallax-bg">
          <div
            className="parallax-title-image"
            style={{
              width: '100%',
              height: '100%',
              backgroundPosition: 'bottom center'
            }}
          />
        </Background>
      </Parallax>
      <Loader isLoading={isLoading(asyncState)}/>
      <div className="content pb-7" style={{minHeight: '200px'}}>
        <div className="text-center pt-6">
          <img src="/img/page-titles/news.svg" alt="News" width="40%" className="page-title"/>
        </div>
        <Container>
          <Loader isLoading={isLoading(asyncState)} />
          <NewsList newsBoxCount={6} showPagination={false} articles={asyncState.data?.data}/>
          <div className="text-center pt-5 pb-5">
            <Link to="/news"
               className="btn btn-outline-secondary p-3 text-uppercase h2 spaced-link px-5"> {t("link.allNews")} </Link>
          </div>
        </Container>
      </div>

    </div>
  )
}


export default Home
