import NewsList from "../components/NewsList";
import {Col, Container, Row} from "react-bootstrap";
import React, {useCallback} from "react";
import Page from "../components/Page";
import article, {Article} from "../service/article";
import useAsync, {isLoading} from "../hooks/async";
import {Paginated} from "../service/types";
import Loader from "../components/Loader";
import {useLocation, Link} from "react-router-dom";
import {useAppContext} from "../contexts/App/appContext";
import {useTranslation} from "react-i18next";


const AllNews = () => {
  const {state} = useAppContext();
  const {t, i18n} = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1");

  const promise = useCallback(() => {
    return article.getArticles({p: page, s: 12}, i18n.language);
  }, [page, i18n.language]);

  const asyncState = useAsync<Paginated<Article>>(promise);
  return (
    <Page titleImage="/img/page-titles/news-all" titleAlt="News" classNameImageTitle="pb-0">
      <Container className="pb-7">
        <Loader isLoading={isLoading(asyncState)}/>
        <Row>
          <Col>
            {state.user && state.user.can_edit &&
            <Link to="/news/create" className="btn btn-primary float-end"> {t('link.create')} </Link>
            }
          </Col>
        </Row>
        <NewsList articles={asyncState.data?.data} showPagination={true} page={page}
                  totalPages={asyncState.data?.total_pages || 1}/>
      </Container>
    </Page>
  )
}

export default AllNews
