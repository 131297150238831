import articles from '../api/articles';
import {uploadImage} from "../utils/uploadImage";
import {PagintaionFilter} from "./types";
import {Upload} from "./upload";
import {ArticleInput} from "../components/ArticleForm";

export interface Article {
  id: number
  title: string
  slug: string
  body: string
  short: string
  published: boolean
  article_category_id: number
  image?: string
  uploads?: Upload[]
  created_at: string
}

export interface ArticleCategory {
  id: number
  name: string
  slug: string
}


export interface ArticleRequest {
  id?: number
  title: string
  body: string
  short: string
  published: boolean
  date?: string,
  image?: string | null
  uploads?: number[]
}

function resolveCategory(category: string, lang: string) {
  return lang === "sk" ? category + "-sk" : category;
}

const ArticleService = {
  getArticles(params: PagintaionFilter, lang: string, category = "news", createAtFilter?: 'past'|'future') {
    return articles.list({
      p: params && params.p ? params.p : 1,
      s: params && params.s ? params.s : 9
    }, resolveCategory(category, lang), createAtFilter);
  },
  getArticleCategories(lang: string) {
    return articles.listCategories(lang);
  },
  createCategory(name: string, lang: string) {
    return articles.createCategory({name, lang});
  },
  editCategory(slug: string, name: string, lang: string) {
    return articles.editCategory(slug, {name, lang});
  },
  getArticle(slug: string, lang: string, category = "news") {
    return articles.detail(slug, resolveCategory(category, lang));
  },
  delete(id: number, lang: string, category = "news") {
    return articles.delete(id, resolveCategory(category, lang));
  },
  async create(data: ArticleInput, lang: string, category = "news") {
    const {body, title, date, short} = data;
    const article: ArticleRequest = {body, title, short: short ? short : "...", date, published: true}
    if (data.image) {
      const r = await uploadImage(data.image);
      article.image = r.file
    }

    if (data.uploads) {
      // @ts-ignore
      article.uploads = await Promise.all(data.uploads.map(async (upload) => {
        if (upload.file) {
          const r = await uploadImage(upload.file, upload.description)
          return r.id;
        }
      }));
    }

    return articles.create(article, resolveCategory(category, lang));
  },
  async edit(data: ArticleInput, lang: string, id: number, actualUploads?: number[], category = "news") {
    const article: ArticleRequest = {id, body: data.body, title: data.title, short: data.short ? data.short: "...", published: true, date: data.date}
    if (data.image && data.image) {
      const r = await uploadImage(data.image);
      article.image = r.file
    }

    if (data.uploads) {
      article.uploads = await Promise.all(data.uploads.map(async (upload) => {
        //@ts-ignore
        const r = await uploadImage(upload.file, upload.description)
        return r.id;
      }));
      if (actualUploads) {
        article.uploads = article.uploads.concat(actualUploads);
      }
    } else {
      article.uploads = actualUploads
    }

    return articles.edit(article, resolveCategory(category, lang));
  }
}

export default ArticleService;
