import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

function Footer() {
  return (
    <div className="footer px-2">
      <Container className="pb-4">
        <hr className="text-secondary mb-4" style={{padding: 0, width: '100%'}}/>
        <Row>
          <Col>
            <div className="float-lg-start text-secondary">
              <img src="/img/logo-bottom-sac-text.svg" alt="logo sac" height="40px" width="auto"/>
            </div>
          </Col>
          <Col>
            <div className="float-lg-end text-uppercase text-secondary small pt-2">
            &copy; Copyright 2021, Ivan Buffa, All Rights Reserved.
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Footer
