import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import React, {useCallback} from "react";
import article, {Article} from "../service/article";
import useAsync, {isLoading} from "../hooks/async";
import Page from "../components/Page";
import NewsForm from "../components/ArticleForm";
import Loader from "../components/Loader";

interface Params {
  slug: string
}

const EditConcert = () => {
  const {i18n} = useTranslation();
  const params = useParams<Params>();

  const promise = useCallback(() => {
    return article.getArticle(params.slug, i18n.language, "concerts");
  }, [i18n.language, params.slug]);

  const asyncState = useAsync<Article>(promise);

  return (
    <Page titleImage="/img/page-titles/edit/edit-concerts" titleAlt="Edit concerts">
      <div>
        <Loader isLoading={isLoading(asyncState)}/>
        {asyncState.data && <NewsForm
            article={asyncState.data}
            handleChange={(input) => {
              if (asyncState.data) {
                return article.edit(input, i18n.language, asyncState.data.id, [], "concerts")
              }
              return Promise.resolve();
            }}
            handleDelete={(id: number) => article.delete(id, i18n.language, "concerts")}
            listURL="/concerts"
            showImageUpload={false}
            showUploads={false}
        />}
      </div>
    </Page>
  )
}

export default EditConcert
