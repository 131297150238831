import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import React, {useEffect, useState} from "react";
import {EditorState} from 'draft-js';
import Loader from "../Loader";
import {uploadImage} from "../../utils/uploadImage";
import {fromDraftStateToHtml, fromHtmlToDraftState} from "../../utils/editor";

const Editor = React.lazy(() => import('react-draft-wysiwyg').then(module => {
  return {default: module.Editor}
}));

interface Props {
  body?: string
  placeholder?: string
  onChange: (content: string) => void
}

const FormEditor: React.FunctionComponent<Props> = ({ body, onChange, ...other}) => {
  let content = EditorState.createEmpty()
  useEffect(() => {
    onBodyStateChange(fromHtmlToDraftState(body));
  }, [body]);

  const [bodyState, onBodyStateChange] = useState(content);

  useEffect(() => {
    onChange(fromDraftStateToHtml(bodyState));
  }, [bodyState, onChange])

  return (
    <React.Suspense fallback={<Loader isLoading={true}/>}>
      <Editor
        editorState={bodyState}
        toolbarClassName="text-editor-toolbar"
        editorClassName="text-editor"
        stripPastedStyles={true}
        {...other}
        // @ts-ignore
        uploadEnabled={true}
        uploadCallback={uploadImage}
        onEditorStateChange={onBodyStateChange}
      />
    </React.Suspense>
  )
}

export default FormEditor
