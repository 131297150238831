import sk from '../lang/sk.json';
import en from '../lang/en.json';
import i18n from 'i18next';
import {initReactI18next} from "react-i18next";

// const lang = localStorage.getItem("lang")  || 'sk';
i18n
  .use(initReactI18next)
  .init({
    interpolation: {
      escapeValue: false,
    },
    lng: "sk",
    fallbackLng: "sk",
    resources: {
      sk: {
        translation: sk
      },
      en: {
        translation: en
      }
    },
  })

export default i18n;
