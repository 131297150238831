import {FunctionComponent, useCallback} from "react";
import gallery from "../../service/gallery";
import useAsync, {isLoading} from "../../hooks/async";
import {Paginated} from "../../service/types";
import upload, {Upload} from "../../service/upload";
import {Button, Col, Row} from "react-bootstrap";
import Loader from "../Loader";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../contexts/App/appContext";

interface Props {
  showSize?: number
}

const VideoMedia: FunctionComponent<Props> = ({showSize = 3}) => {
  const {t} = useTranslation();
  const {state} = useAppContext();
  const videos = useCallback(() => {
    return gallery.getCategoryUploads("video", showSize);
  }, [showSize]);

  const videosState = useAsync<Paginated<Upload>>(videos);
  return (
    <Row className="py-4">
      <Loader isLoading={isLoading(videosState)}/>
      {videosState.data && videosState.data.data.map((video) => {
        return (
          <Col lg={4} xs={12} key={`video-${video.id}`}>
            {state.user && state.user.can_edit && (
              <div className="my-4 text-end">
               <Button variant="danger" onClick={() => upload.delete("media", "video", video.id).then(() => window.location.reload())}>{t('link.delete')}</Button>
               <Link to={`/media/video/${video.id}/edit`} className="btn btn-primary ms-2">{t('link.update')}</Link>
              </div>
            )}
            <iframe title={video.description} className="video" id="video" src={video.file} width="100%" height="250px" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
            <div className="pt-2 font-weight-medium text-center">{video.description}</div>
          </Col>
        )
      })
      }
    </Row>
  )
}

export default VideoMedia
