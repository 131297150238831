import {useParams} from "react-router-dom";
import React, {useCallback} from "react";
import article, {Article} from "../service/article";
import useAsync, {isLoading} from "../hooks/async";
import Page from "../components/Page";
import NewsForm from "../components/ArticleForm";
import Loader from "../components/Loader";

interface Params {
  slug: string
  category: string
}

const EditComposition = () => {
  const params = useParams<Params>();

  const promise = useCallback(() => {
    return article.getArticle(params.slug, "", params.category);
  }, [params.slug, params.category]);

  const asyncState = useAsync<Article>(promise);

  return (
    <Page titleImage="/img/page-titles/edit/edit-compositions" titleAlt="Edit composition">
      <div>
        <Loader isLoading={isLoading(asyncState)}/>
        {asyncState.data && <NewsForm
            article={asyncState.data}
            handleChange={(input) => {
              if (asyncState.data) {
                return article.edit(input, "", asyncState.data.id, [], params.category)
              }
              return Promise.resolve();
            }}
            handleDelete={(id: number) => article.delete(id, "", params.category)}
            listURL="/compositions"
            showImageUpload={true}
            showUploads={false}
            showShort={true}
        />}
      </div>
    </Page>
  )
}

export default EditComposition
