import {Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Article} from "../../service/article";
import parse from "html-react-parser";
import dayjs from "dayjs";

interface Props {
  last?: boolean
  composition: Article
  category_slug: string
}

const Composition: React.FunctionComponent<Props> = ({last = false, composition, category_slug}) => {
  return (
    <Col md={6} className="position-relative">
      <div>
        {dayjs(composition.created_at).format("YYYY")}
      </div>
      <Link to={`/compositions/${category_slug}/${composition.slug}`} className="link-secondary text-decoration-none mb-5 d-inline-block">
        <h4 className="text-secondary text-capitalize normal-spacing normal-transform h5">{composition.title}</h4>
        <span>{parse(composition.short)}</span>
      </Link>
      {!last &&
      <hr className="hr-full-blue my-4"/>
      }
    </Col>
  )
}

export default Composition;
