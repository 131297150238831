import users from '../api/users';

export interface User {
  is_admin: boolean,
  can_edit: boolean,
  email: string,
  first_name: string,
  last_name: string
}

const UserService = {
  login(email: string, password: string) {
    return users.login(email, password);
  },
  userInfo() {
    return users.info();
  },
  forgotPassword(email: string) {
    return users.forgotPassword(email);
  },
  resetPassword(password: string, confirm: string, token: string) {
    return users.resetPassword(
      password,
      confirm,
      token);
  }
}

export default UserService;
