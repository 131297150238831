import React from "react";
import {Alert} from "react-bootstrap";

interface Props {
  error: string | null
}

const Error: React.FunctionComponent<Props> = ({error}) => {
  return (
    <Alert variant="danger" show={error !== null}>
      {error}
    </Alert>
  )
}

export default Error
