import upload, {GALLERY_TYPE} from "../api/upload";

export interface Upload {
  file: string
  thumbnail?: string
  description: string
  id: number
}

const UploadService = {
  async upload(images: Blob[], category: string) {
    for (const image of images) {
      const formData = new FormData();
      formData.append('file', image)
      await upload.upload(formData, category, GALLERY_TYPE);
    }
  },
  uploadOne(file: Blob | null, category: string, description: string|null = null, youtube_suffix?: string) {
    const formData = new FormData();
    if (youtube_suffix) {
      formData.append('youtube_suffix', youtube_suffix)
    } else if (file) {
      formData.append('file', file)
    }
    if (description != null) {
      formData.append('description', description)
    }
    return upload.upload(formData, category, GALLERY_TYPE);
  },
  getUploads(type: string, page: number, category: string) {
    return upload.listUploads({p: page, s: 10}, type, category);
  },
  getUpload(type: string, category: string, id: number) {
    return upload.getUpload(category, type, id)
  },
  edit(type: string, category: string, id: number, description: string) {
    return upload.edit(category, type, id, description)
  },
  delete(type: string, category: string, id: number) {
    return upload.delete(category, type, id);
  }
}


export default UploadService
