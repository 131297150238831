import {Col, Row} from "react-bootstrap";
import DateBox from "../DateBox";
import React from "react";
import {Article} from "../../service/article";
import parse from "html-react-parser";
import {Link} from "react-router-dom";
import {useAppContext} from "../../contexts/App/appContext";
import {useTranslation} from "react-i18next";

interface Props {
  last?: boolean,
  concert: Article
}

const ConcertEvent: React.FunctionComponent<Props> = ({last = false, concert}) => {
  const {state} = useAppContext();
  const {t} = useTranslation();

  return (
    <Row className={last ? 'pb-6' : ''}>
      <Col xs={12} md={3} className="ps-7 pb-4">
        <DateBox date={concert?.created_at}/>
      </Col>
      <Col xs={12} md={9} className="pe-lg-7">
        <h4 className="pb-2">
          {concert?.title}
        </h4>
        <span>
          { concert?.body && parse(concert?.body) }
        </span>
        {state.user && state.user.can_edit &&
        <Link to={`/concerts/${concert.slug}/edit`} className="btn btn-primary float-end"> {t('link.update')} </Link>
        }
      </Col>
      {!last &&
      <hr/>
      }
    </Row>
  )
}

export default ConcertEvent;
