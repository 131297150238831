import {FunctionComponent, ReactElement} from "react";
import NewsBox from "../NewsBox";
import {Col, Row} from "react-bootstrap";
import {Article} from "../../service/article";
import Paginator from "../Pagination";

interface Props {
  newsBoxCount?: number
  articles?: Article[]
  page?: number
  totalPages?: number
  showPagination?: boolean
}

const NewsList: FunctionComponent<Props> = ({newsBoxCount = 12, showPagination = true, articles, page, totalPages}) => {
  let boxes: ReactElement[] = []

  if (articles) {
   boxes = articles.map((article) =>
      (
        <Col lg="4" md="6" key={'article-' + article.id} className="mt-5">
          <NewsBox article={article}/>
        </Col>
      )
    );
  }

  const pagination = showPagination ? (
    <Paginator page={page} totalPages={totalPages}/>
  ) : null

  return (
    <Row className="news-list">
      {boxes}
      {pagination}
    </Row>
  )
}

export default NewsList
