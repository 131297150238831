import React, {useCallback} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Page from "../components/Page";
import {useTranslation} from "react-i18next";
import DownloadLink from "../components/DownloadLink";
import {useAppContext} from "../contexts/App/appContext";
import {Link, useLocation} from "react-router-dom";
import article, {Article} from "../service/article";
import useAsync, {isLoading} from "../hooks/async";
import {Paginated} from "../service/types";
import parse from "html-react-parser";
import Loader from "../components/Loader";


const About = () => {
  const {state} = useAppContext();
  const {t, i18n} = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1");

  const promise = useCallback(() => {
    return article.getArticles({p: page, s: 999}, i18n.language, "about");
  }, [page, i18n.language]);

  const asyncState = useAsync<Paginated<Article>>(promise);

  const articles = asyncState.data?.data.map((article, index) => {
    let content = (
      <Row className="text-center">
        <h4 className="pb-5">{article.title}</h4>
        <Col className="text-justify">
          {parse(article.body)}
        </Col>
      </Row>
    )
    if (article.image) {
      content = (
        <Row>
          <Col md={6}>
            <img src={article.image} alt="About" className="img-fluid float-end"/>
          </Col>
          <Col md={6} className="text-start">
            {parse(article.body)}
          </Col>
        </Row>
      )
    }
    return (
      <div key={`about-${article.id}`}>
        <Row className="mb-4">
          <Col>
            {state.user && state.user.can_edit &&
            <Link to={`/about/${article.slug}/edit`} className="btn btn-primary float-end"> {t('link.update')} </Link>
            }
          </Col>
        </Row>
        {content}
        {
          (article.uploads ||
            (asyncState.data && asyncState.data.data.length < index)
          ) && (<hr/>)
        }
        {article.uploads && article.uploads.map((u) => (
          <Row>
            <DownloadLink name={u.description || t('link.attachmentFallback')} link={u.file} key={u.file}/>
          </Row>
        ))
        }
      </div>
    )
  })
  return (
    <Page titleImage="/img/page-titles/about" titleAlt="About title">
      <Container className="pb-7 px-lg-7 px-4">
        <Loader isLoading={isLoading(asyncState)}/>
        {articles}
      </Container>
    </Page>
  )
}

export default About
