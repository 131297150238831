import React from "react";
import {useAppContext} from "../../contexts/App/appContext";

interface Props {
  titleImage: string;
  titleAlt: string;
  children: React.ReactElement;
  classNameImageTitle?: string;
}

const Page: React.FunctionComponent<Props> = ({children, titleImage, titleAlt, classNameImageTitle = "pb-5"}) => {
 const {state} = useAppContext();
  return (
    <div className="page content">
      <div className={`text-center pt-6 ${classNameImageTitle}`}>
        <img src={`${state.lang === "sk" ? titleImage + "-sk" : titleImage}.svg`} alt={titleAlt} width="40%" className="page-title"/>
      </div>
      {children}
    </div>
  )
}

export default Page;
