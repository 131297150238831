import Page from "../components/Page";
import {Col, Container, Row} from "react-bootstrap";
import VideoMedia from "../components/VideoMedia";
import {Link} from "react-router-dom";
import React from "react";
import {useAppContext} from "../contexts/App/appContext";
import {useTranslation} from "react-i18next";

const Media = () => {
  const {state} = useAppContext();
  const {t} = useTranslation();
  return (
    <Page titleImage="/img/page-titles/media" titleAlt="media">
      <div>
        <Container>
          <Row>
            <Col xs={12}>
              {state.user && state.user.can_edit &&
              <Link to="/media/video/upload" className="btn btn-primary float-end"> {t('link.create')} </Link>
              }
            </Col>
            <Col xs={12}>
              <h5>{t('titles.Video')}</h5>
              <VideoMedia showSize={9999}/>
            </Col>
          </Row>
        </Container>
      </div>
    </Page>
  )
}

export default Media
