import React from "react";

interface Props {
    isLoading: boolean
}

const Loader: React.FunctionComponent<Props> = ({isLoading= true}) => {
    return (
        <div className={isLoading ? 'loader show' : 'loader'}>
            <div className="inner-loader" />
        </div>
    );
}

export default Loader;
