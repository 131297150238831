import {SubmitHandler, useForm} from "react-hook-form";
import {FunctionComponent, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import UploadFile from "../UploadFiles";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import Error from "../Error";
import Editor from "../Editor";
import dayjs from "dayjs";
import {Article} from "../../service/article";
import {Upload} from "../../service/upload";

export interface BlobWithDescription {
  file?: File,
  youtube_suffix?: string
  description?: string
}

export interface ArticleInput {
  title: string
  body: string
  image: File | null
  short?: string
  uploads?: BlobWithDescription[] | null,
  date?: string
}

interface Props {
  handleChange: (data: ArticleInput, actualUploads?: Upload[]) => Promise<void>;
  handleDelete?: (id: number) => Promise<void>;
  listURL: string
  showImageUpload?: boolean
  showUploads?: boolean
  showDate?: boolean
  showShort?: boolean
  article?: Article | null
}

const NewsForm: FunctionComponent<Props> = (
  {
    handleChange,
    listURL,
    article,
    showImageUpload = false,
    showUploads = false,
    showDate = true,
    showShort = false,
    handleDelete,
  }) => {

  const {t} = useTranslation();
  const {handleSubmit, register, formState: {errors}, reset} = useForm<ArticleInput>();
  const [err, setErr] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [uploads, setUploads] = useState<File[] | null>();
  const [existingUploads, setExistingUploads] = useState<Upload[] | undefined>();
  const [uploadDescriptions, setUploadDescriptions] = useState<string[]>([]);
  const [images, setImages] = useState<File[] | null>();
  const [body, setBody] = useState("");
  const [short, setShort] = useState("");
  const history = useHistory();

  const onDelete = (id: number) => {
    setLoading(true)
    if (handleDelete) {
      return handleDelete(id).then(() => {
        setLoading(false)
        history.push(listURL)
      }).catch((e) => {
        setLoading(false);
        setErr(e.message)
      })
    }
    return Promise.resolve();
  }

  useEffect(() => {
    if (article) {
      setExistingUploads(article?.uploads)
      reset({
        title: article.title,
        date: dayjs(article.created_at).format("YYYY-MM-DD")
      })
    }
  }, [reset, article])

  const onSubmit: SubmitHandler<ArticleInput> = (data) => {
    setLoading(true)
    const change: ArticleInput = {
      title: data.title,
      body,
      image: images && images.length ? images[0] : null,
      uploads: uploads && uploads.map((file, i) => {
        return {file, description: uploadDescriptions[i]}
      }),
    }
    if (showDate) {
      change.date = data.date
    }

    if (showShort) {
      change.short = short
    }

    handleChange(change, existingUploads).then(() => {
      setLoading(false);
      history.push(listURL);
    }).catch((e) => {
      setLoading(false);
      setErr(e.message)
    });
  }

  const handleChangeUpload = (files: File[]) => setUploads(files)
  const handleChangeImage = (images: File[]) => setImages(images)
  const updateUploadDescription = (value: string, index: number) => {
    uploadDescriptions[index] = value
    setUploadDescriptions(uploadDescriptions);
  }

  return (
    <Container>
      {article && article.id && handleDelete &&
      <Row>
          <Col className="text-end">
              <Button variant="danger" onClick={() => onDelete(article.id)}>{t('link.delete')}</Button>
          </Col>
      </Row>
      }
      <Row>
        <Col>
          {showImageUpload &&
          <UploadFile onChange={handleChangeImage} multiple={false} label={t("form.article.Image")} zone={true}
                      defaultImage={article && article.image ? article.image : undefined} formats="image/*"/>}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Error error={err}/>
            <Form.Group className="mb-3" controlId="email">
              <Form.Control
                type="text"
                defaultValue={article ? article.title : ""}
                placeholder={t("form.article.Title")}
                {...register("title", {
                  required: {
                    value: true,
                    message: t("form.validationMessages.required")
                  },
                  minLength: {
                    value: 3,
                    message: t("form.validationMessages.min", {field: t("form.article.Title")})
                  }
                })}/>
              <Form.Text className="text-danger">
                {errors.title && errors.title.message}
              </Form.Text>
            </Form.Group>

            {
              showShort &&
                <Editor onChange={(content) => setShort(content)} body={article ? article.short : ""} placeholder={t('form.article.Description')}/>
            }

            <Editor onChange={(content) => setBody(content)} body={article ? article.body : ""} placeholder={t('form.article.Content')}/>
            {showDate && (
              <Form.Group>
                <Form.Control
                  type="date"
                  defaultValue={article ? dayjs(article.created_at).format('YYYY-MM-DD') : dayjs().format("YYYY-MM-DD")}
                  placeholder={t("form.article.Date")}
                  {...register("date")}/>
                <Form.Text className="text-danger">
                  {errors.date && errors.date.message}
                </Form.Text>
              </Form.Group>
            )}

            {showUploads &&
            <UploadFile onChange={handleChangeUpload} multiple={true} label={t("form.article.Uploads")}/>}
            {uploads && uploads.map((u, index) => {
              return (
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail" key={`uploads-${u.name}`}>
                  <Form.Label column sm="2">
                    {u.name}
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control type="text" placeholder="Upload name"
                                  onChange={(e) => updateUploadDescription(e.target.value, index)}/>
                  </Col>
                </Form.Group>
              )
            })}
            {existingUploads && existingUploads.map((u, index) => {
              return (
                <div key={u.file}>
                  <h6>Existing Uploads</h6>
                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail" key={`uploads-${u.description}`}>
                    <Col sm="10">
                      <Form.Control
                        type="text"
                        placeholder="Upload name"
                        defaultValue={u.description}
                        onChange={(e) => updateUploadDescription(e.target.value, index)}
                      />
                    </Col>
                    <Col sm="2">
                      <Button className="btn-outline-danger" onClick={() => {
                        setExistingUploads(existingUploads.filter((f) => f.file !== u.file));
                      }}>X</Button>
                    </Col>
                  </Form.Group>
                </div>
              )
            })
            }
            <Form.Group className="text-end">
              <Button type="submit" disabled={isLoading}>{article ? t('link.update') : t('link.create')}</Button>
            </Form.Group>
          </form>
        </Col>
      </Row>
    </Container>
  )
}

export default NewsForm
