import {FunctionComponent, useCallback} from "react";
import gallery from "../../service/gallery";
import useAsync, {isLoading} from "../../hooks/async";
import {Paginated} from "../../service/types";
import {Upload} from "../../service/upload";
import {Row} from "react-bootstrap";
import Loader from "../Loader";
import ImageGallery from "../ImageGallery";

interface Props {
  showSize?: number
}

const PhotoMedia: FunctionComponent<Props> = ({showSize = 3}) => {
  const photos = useCallback(() => {
    return gallery.getCategoryUploads("photo", showSize);
  }, [showSize]);

  const PhotosState = useAsync<Paginated<Upload>>(photos);
  return (
    <Row className="py-4">
      <Loader isLoading={isLoading(PhotosState)}/>
      { PhotosState.data && <ImageGallery images={PhotosState.data.data} />}
    </Row>
  )
}

export default PhotoMedia
