import gallery, {GALLERY_TYPE} from "../api/upload";

const GalleryService = {
    getCategoryUploads(category: string, size: number = 9999) {
        return gallery.listUploads({
            p: 1,
            s: size,
        }, GALLERY_TYPE, category);
    },
    getUploadsCategories() {
        return gallery.list(GALLERY_TYPE);
    },
    create(name: string, description: string) {

        return gallery.createCategory({
            name,
            description,
            type: GALLERY_TYPE,
            subPath: name.toLowerCase().replace(' ', '-')
        })
    }
};

export default GalleryService;
