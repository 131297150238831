import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import React, {useCallback} from "react";
import article, {Article} from "../service/article";
import useAsync, {isLoading} from "../hooks/async";
import Page from "../components/Page";
import NewsForm from "../components/ArticleForm";
import Loader from "../components/Loader";

interface Params {
  slug: string
}

const EditAbout = () => {
  const {i18n} = useTranslation();
  const params = useParams<Params>();

  const promise = useCallback(() => {
    return article.getArticle(params.slug, i18n.language, "about");
  }, [i18n.language, params.slug]);

  const asyncState = useAsync<Article>(promise);

  return (
    <Page titleImage="/img/page-titles/edit/edit-about" titleAlt="Edit about">
      <div>
        <Loader isLoading={isLoading(asyncState)}/>
        {asyncState.data && <NewsForm
            article={asyncState.data}
            handleChange={(input, actualUploads) => {
              if (asyncState.data) {
                return article.edit(input, i18n.language, asyncState.data.id, actualUploads ? actualUploads.map((u) => u.id) : [], "about")
              }
              return Promise.resolve();
            }}
            listURL="/about"
            showImageUpload={true}
            showUploads={true}
            showDate={false}
        />}
      </div>
    </Page>
  )
}

export default EditAbout
