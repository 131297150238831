import {Container, Nav, Navbar} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import {SET_LANG, useAppContext} from "../../contexts/App/appContext";
import {useTranslation} from "react-i18next";

function MainNav() {
  const {state, dispatch} = useAppContext();
  const location = useLocation();
  const {t} = useTranslation();
  const [menuClass, setMenuClass] = useState("App-header fixed-top subpage");
  const [actualPage, setActualPage] = useState("/");
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (location.pathname === "/") {
      setMenuClass("App-header fixed-top")
    } else {
      setMenuClass("App-header fixed-top subpage")
    }

    if (location.pathname !== actualPage) {
      setActualPage(location.pathname);
      setExpanded(false);
    }
  }, [actualPage, location.pathname])

  useEffect(() => {
    window.addEventListener('scroll', getWindowHeight);
    getWindowHeight();
    return () => window.removeEventListener('scroll', getWindowHeight);
  });

  const getWindowHeight = () => {
    const distanceY = document.documentElement.scrollTop
    const shrinkOn = 200;

    //Now In the condition change the state to smaller so if the condition is true it will change to smaller otherwise to default state
    if (distanceY > shrinkOn) {
      setMenuClass("App-header fixed-top");
    } else {
      if (location.pathname !== "/") {
        setMenuClass("App-header fixed-top subpage");
      }
    }
  }


  return (
    <header className={menuClass}>
      <Container>
        <Navbar bg="dark" variant="dark" expand="lg"
                expanded={expanded}
                className="main-navbar text-uppercase">
          <Navbar.Brand href="/" className="logo" style={{display: location.pathname === "/" ? "none" : "block"}}>
            <img src="/img/logo-center.svg" alt="logo" height="100%" width="100%" className="logo-desktop"/>
            <img src="/img/title-logo-center.svg" alt="logo" height="100%" width="100%" className="logo-mobile"/>
            <h6 className="text-logo mb-0 pe-5">IVAN buffa</h6>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)}/>
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
            <Nav
              activeKey="/"
              className="justify-content-between me-auto"
            >
              <Nav.Item>
                <Nav.Link to="/news" exact as={NavLink}>{t("nav.News")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link to="/about" as={NavLink}>{t("nav.About")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link to="/concerts" as={NavLink}>{t("nav.Concerts")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link to="/compositions" as={NavLink}>{t("nav.Compositions")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link to="/discography" as={NavLink}>{t("nav.Discography")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link to="/media" as={NavLink}>{t("nav.Media")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link to="/contact" as={NavLink}>{t("nav.Contact")}</Nav.Link>
              </Nav.Item>
              <Nav.Item className="justify-self-end lang-menu">
                <a href="/" className={state.lang === "sk" ? "active" : ""} onClick={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: SET_LANG,
                    value: 'sk'
                  })
                }}>SK</a>
                <span className="text-white">|</span>
                <a href="/" className={state.lang === "en" ? "active" : ""} onClick={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: SET_LANG,
                    value: 'en'
                  })
                }}>EN</a>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  )
}

export default MainNav
