import {Col, Row} from "react-bootstrap";
import Player from "../Player";
import React from "react";
import {Article} from "../../service/article";
import parse from "html-react-parser";
import {Link} from "react-router-dom";
import {useAppContext} from "../../contexts/App/appContext";
import {useTranslation} from "react-i18next";

interface Props {
  last?: boolean
  album: Article
}

const DiscographyAlbum: React.FunctionComponent<Props> = ({last = false, album}) => {
  let samples: React.ReactElement[] = [];
  const {state} = useAppContext();
  const {t} = useTranslation();

  if (album.uploads) {
    samples = album.uploads.map((u) => <Player key={u.file} src={u.file} name={u.description}/>)
  }
  return (
    <Row className="px-4 px-lg-0">
      <Col md={6} className="ps-lg-7 mb-4">
        <img src={album.image} alt="album" className="img-fluid float-end" width="400px"/>
      </Col>
      <Col md={6} className="pe-lg-7">
        <h4 className="pb-4">
          {album.title}
        </h4>
        <span>
          {parse(album.body)}
        </span>
        <div className="pt-4">
          {samples}
        </div>
        <div className="pt-4">
          {state.user && state.user.can_edit &&
          <Link to={`/discography/${album.slug}/edit`} className="btn btn-primary"> {t('link.update')} </Link>
          }
        </div>
      </Col>
      {!last &&
      <hr/>
      }
    </Row>
  )
}

export default DiscographyAlbum
