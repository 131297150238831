import htmlToDraft from "html-to-draftjs";
import {ContentState, EditorState} from "draft-js";
import {stateToHTML} from "draft-js-export-html";


export function fromHtmlToDraftState(content) {
    const blocksFromHtml = htmlToDraft(content);
    const {contentBlocks, entityMap} = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState);
}

export function fromDraftStateToHtml(contentState) {
    return stateToHTML(contentState.getCurrentContent(), {
        defaultBlockTag: 'div',
        entityStyleFn: (entity) => {
            const entityType = entity.get('type').toLowerCase();
            if (entityType === 'link') {
                const data = entity.getData();
                return {
                    element: 'a',
                    attributes: {
                        href: data.url,
                        target: data.targetOption
                    },
                };
            }
        },
    })
}
