import Page from "../components/Page";
import NewsForm from "../components/ArticleForm";
import ArticleService from "../service/article";
import {useTranslation} from "react-i18next";


const CreateConcert = () => {
  const {i18n} = useTranslation();

  return (
    <Page titleImage="/img/page-titles/create/create-concerts" titleAlt={"Create Concert"}>
      <NewsForm
        handleChange={(input) => {
          return ArticleService.create(input, i18n.language, "concerts")
        }}
        listURL="/concerts"
        showImageUpload={false}
        showUploads={false}
      />
    </Page>
  )
}

export default CreateConcert;
