import React, {useReducer, useContext, Reducer} from "react";
import localStorageBackwardCompatibilityInit from '../../utils/localStorage';
import i18n from "../../utils/i18n";
import {User} from "../../service/user";

localStorageBackwardCompatibilityInit();

export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_LANG = 'SET_LANG';
export const SET_MP3_PLAYING = 'SET_MP3_PLAYING';

interface AppContextState {
  title: string,
  user: User | null,
  token: string | null,
  lang: 'sk'|'en',
  mp3Playing?: string
}

export interface Action {
  type: string,
  value: any
}

let reducer = (state: AppContextState, action: Action) => {
  switch (action.type) {
    case SET_USER_INFO:
      return {
        ...state, user: {
          ...state.user,
          ...action.value
        }
      };
    case SET_TOKEN:
      localStorage.setItem("token", action.value);
      return {...state, token: action.value};
    case SET_PAGE_TITLE:
      if (state.title === action.value) {
        return state
      }
      return {...state, title: action.value}
    case SET_MP3_PLAYING:
      return {...state, mp3Playing: action.value}
    case SET_LANG:
      i18n.changeLanguage(action.value);
      localStorage.setItem("lang", action.value);
      return {...state, lang: action.value}
    default:
      return state;
  }
};

const initialState: AppContextState = {
  user: null,
  token: localStorage.getItem("token") || null,
  title: "",
  lang: localStorage.getItem("lang") === "en" ?  'en' : 'sk'
}

const AppContext = React.createContext<{state: AppContextState, dispatch: React.Dispatch<Action>}>({state: initialState, dispatch: () => null});

function AppContextProvider(props: React.PropsWithChildren<any>) {
  const [state, dispatch] = useReducer<Reducer<AppContextState, Action>>(reducer, initialState);

  return (
    <AppContext.Provider value={{state, dispatch}}>
        {props.children}
    </AppContext.Provider>
  );
}

const useAppContext = () => useContext(AppContext);

export {AppContext, AppContextProvider, reducer, initialState, useAppContext};
