import React, {useCallback} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Page from "../components/Page";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import {useAppContext} from "../contexts/App/appContext";
import article, {Article} from "../service/article";
import useAsync, {isLoading} from "../hooks/async";
import parse from "html-react-parser";
import Loader from "../components/Loader";

interface Params {
  slug: string
  category: string
}

const CompositionDetail = () => {
  const {t} = useTranslation();
  const params = useParams<Params>();

  const {state} = useAppContext();

  const promise = useCallback(() => {
    return article.getArticle(params.slug, "", params.category);
  }, [params.slug, params.category]);

  const asyncState = useAsync<Article>(promise);
  return (
    <Page titleAlt="Compositions detail" titleImage="/img/page-titles/compositions-detail">
      <Container className="px-7">
        <Loader isLoading={isLoading(asyncState)}/>
        {state.user && state.user.can_edit &&
        <Link to={`/compositions/${params.category}/${params.slug}/edit`} className="btn btn-primary float-end"> {t('link.update')} </Link>
        }
        {asyncState.data && (
          <Row className="mt-3 px-6">
            <Col md={6}>
              <img src={asyncState.data.image} alt="news detail" className="float-end img-fluid" width="400px"/>
            </Col>
            <Col md={6} className="text-start">
              <h4>
                {asyncState.data.title}
              </h4>
              {asyncState.data?.body && parse(asyncState.data?.body)}
            </Col>
          </Row>
        )
        }
        <hr/>

        <div className="text-center pt-5 pb-5">
          <a href="/compositions"
             className="btn btn-outline-secondary p-3 text-uppercase h2 spaced-link px-5"> {t('link.allCompositions')} </a>
        </div>
      </Container>
    </Page>
  )
}

export default CompositionDetail
