import {Col} from "react-bootstrap";
import React, {FunctionComponent} from "react";

interface Props {
  name: string
  link: string
}

const DownloadLink: FunctionComponent<Props> = ({name, link}) => {
  return (
    <Col xs={12} className="text-center pb-3">
      <div className="download-icon text-uppercase">
        <a href={link} target="_blank" rel="noreferrer" className="link-secondary text-decoration-none">
          <img src="/img/icons/download.svg" alt="download" height="15px" className="mx-2"/>
          <span>
           {name}
          </span>
        </a>
      </div>
    </Col>
  )
}

export default DownloadLink
