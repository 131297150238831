import {FunctionComponent, useCallback} from "react";
import useAsync, {isLoading} from "../../hooks/async";
import {Paginated} from "../../service/types";
import {Col, Row} from "react-bootstrap";
import Loader from "../Loader";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../contexts/App/appContext";
import article, {Article} from "../../service/article";

interface Props {
  showSize?: number
}

const PressMedia: FunctionComponent<Props> = ({showSize = 3}) => {
  const {t} = useTranslation();
  const {state} = useAppContext();
  const articles = useCallback(() => {
    return article.getArticles({p: 1, s: showSize}, "", "press");
  }, [showSize]);

  const articleState = useAsync<Paginated<Article>>(articles);
  return (
    <Row className="py-4">
      <Loader isLoading={isLoading(articleState)}/>
      {articleState.data && articleState.data.data.map((article) => {
        return (
          <Col lg={4} xs={12} key={`article-${article.id}`}>
            {state.user && state.user.can_edit && (
              <div className="my-4 text-end">
                <Link to={`/media/press/${article.slug}/edit`}
                      className="btn btn-primary ms-2">{t('link.update')}</Link>
              </div>
            )}
            <a href={article.short} target="_blank" className="text-center" rel="noreferrer">
              <img src={article.image} alt="press" className="img-fluid p-6"/>
              <div className="pt-2 font-weight-medium text-center text-black text-decoration-none">{article.title}</div>
            </a>
          </Col>
        )
      })
      }
    </Row>
  )
}

export default PressMedia
