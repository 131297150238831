import {Route, Redirect} from "react-router-dom";
import React from "react";
import {User} from "../../service/user";
import {RouteProps} from "react-router";
import {useAppContext} from "../../contexts/App/appContext";

export function isAllowed(permType: string, user: User | null) {
  if (permType === 'admin') {
    return user && user.is_admin
  } else if (permType === 'editor') {
    return user && (user.can_edit || user.is_admin)
  } else if (permType === 'logged') {
    return user !== null
  } else if (permType === 'notLogged') {
    return user === null
  }
}

interface Props extends RouteProps {
  neededPerm: 'notLogged' | 'logged' | 'editor' | 'admin',
}

const ProtectedRoute: React.FunctionComponent<Props> = ({children, neededPerm, ...rest}) => {
  const {state} = useAppContext();

  return (
    <Route
      {...rest}
      render={({location}) =>
        isAllowed(neededPerm, state.user) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/not-found",
              state: {from: location}
            }}
          />
        )
      }
    />
  );
}

export default ProtectedRoute
